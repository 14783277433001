import { fireStorage } from './firebase';
import { readAndCompressImage } from 'browser-image-resizer';
import Logger from '../services/Log';

const logger = new Logger('FIREIMAGE');

/**
 * stores images in storage at images/${userId}/entries/${entryId}/${imageId};
 */
export default class FireImage {
  RESIZE_CONFIG = {
    maxWidth: 2000,
    maxHeight: 2000,
  };

  constructor(settings) {
    Object.assign(this, settings);
    this.storageRef = fireStorage
      .ref()
      .child(`images/${this.userId}/entries/${this.entryId}/${this.id}`);
  }

  async put(file) {
    logger.debug('putting image...');
    this._file = file;
    const image = await readAndCompressImage(file, this.RESIZE_CONFIG);
    this.remote = await this.storageRef.put(image);
    logger.debug('put image.');
  }

  async delete() {
    logger.debug('deleting image...');
    await this.storageRef.delete();
    logger.debug('deleted image.');
  }

  async getLink() {
    logger.debug('putting image...');
    return this.storageRef.getDownloadURL();
  }
}
