
import { firestore } from './firebase';
import Logger from '../services/Log';

const logger = new Logger('FIRE USER MODEL');

function isOverAYear(date) {
  return new Date() - date > 365 * 24 * 3600 * 1000;
}

function isOverTwoMonths(date) {
  return new Date() - date > 31 * 2 * 24 * 3600 * 1000;
}

// TODO: extend a base model
export default class FireUserModel /* extends FireBaseModel*/ {
  /**
   * PROPERTIES
   */
  databaseRef; // move this to parent
  email;
  startDate;
  id;
  lastUpdated;
  lookupMethod;

  constructor(fields) {
    this.databaseRef = firestore.collection('user');
    Object.assign(this, fields);
  }

  // class method
  getById(id) {
    logger.debug('getting by id', id);
    return this.databaseRef
      .doc(id)
      .get()
      .then(this.docSnapToPlainObject);
  }

  /**
   * only use for creating new users
   * @param authUser from firebase auth
   */
  async create(authUser) {
    logger.debug('creating user from', authUser);
    this.id = authUser.uid;
    this.creationTime = authUser.metadata.creationTime;
    this.email = authUser.email;

    const creationDate = new Date(this.creationTime);
    if (isOverAYear(creationDate)) {
      logger.debug('user has been active for over a year');
      this.lookupMethod = 'date_and_month';
    } else if (isOverTwoMonths(creationDate)) {
      logger.debug('user has been active for over a month');
      this.lookupMethod = 'date';
    } else {
      logger.debug('user has been active for under a month');
      this.lookupMethod = 'day';
    }

    return await this.put();
  }

  put() {
    let user = { ...this, lastUpdated: new Date() };
    logger.debug('put', user);
    delete user.databaseRef;
    return this.databaseRef
      .doc(this.id)
      .set(user)
      .then(() => this.getById(this.id));
  }

  async patch(data = {}) {
    data.lastUpdated = new Date();
    logger.debug('patch', data);
    await this.databaseRef.doc(this.id).update(data);
    return await this.databaseRef
      .doc(this.id)
      .get()
      .then(this.docSnapToPlainObject);
  }

  async getByChildren(children) {
    logger.debug('get by children', children);
    // starts out as CollectionRef, ends up being Query
    // probably should change
    let queriable = this.databaseRef;
    let entries = [];

    for (let key in children) {
      queriable = await queriable.where(key, '==', children[key]);
    }
    await queriable.get().then(querySnapShot => {
      querySnapShot.forEach(doc => {
        entries.push(this.docSnapToPlainObject(doc));
      });
    });
    return entries;
  }

  docSnapToPlainObject(docSnap) {
    logger.debug('action complete', docSnap.data());
    return {
      id: docSnap.id,
      ...docSnap.data(),
    };
  }
}
