import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Fab } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import './NewEntryDialog.css';
import CreateEntryDialog from './CreateEntryDialog';
import EntryUpdateForm from './EntryUpdateForm';

class NewEntryDialog extends Component {
  state = {
    entry: null,
    step: null,
  };

  startCreate = () => this.setState({ step: 'create' });
  createCompleted = entry => this.setState({ step: 'update', entry });
  createCancelled = () => this.setState({ step: null });
  updateCompleted = () => {
    this.setState({ step: null, entry: null });
    if (this.props.onSaveComplete) this.props.onSaveComplete();
  };
  updateCancelled = () => this.setState({ step: null, entry: null });

  render() {
    return (
      <div
        className={`NewEntryDialog ${
          this.props.onboarding ? '' : 'floating-fixed'
          }`}
      >
        <div className="new-entry-container">
          <div
            className={`${this.props.onboarding ? 'pulse-background' : ''}`}
          />
          <Fab
            size="small"
            color="secondary"
            aria-label="Edit"
            className="NewEntryFab"
            onClick={this.startCreate}
          >
            <Add />
          </Fab>
        </div>

        <CreateEntryDialog
          open={this.state.step === 'create'}
          onboarding={this.props.onboarding}
          onSaveComplete={this.createCompleted}
          onCancel={this.createCancelled}
        />

        {this.state.entry ? (
          <EntryUpdateForm
            selected={this.state.step === 'update'}
            entry={this.state.entry}
            onboarding={this.props.onboarding}
            onSaveComplete={this.updateCompleted}
            deselect={this.updateCancelled}
          />
        ) : null}
      </div>
    );
  }
}

NewEntryDialog.propTypes = {
  onboarding: PropTypes.bool,
};

const mapStateToProps = state => ({ ...state });
const mapDispatchToProps = dispatch => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewEntryDialog);
