export default class Logger {
  debug;
  warn;
  log;
  error;
  constructor(_tag) {
    Object.keys(console).forEach(key => {
      this[key] = function() {
        const args = [`${_tag}:`, ...arguments];
        console[key].apply(this, args);
      };
    });
  }
}
