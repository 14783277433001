import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  withMobileDialog, // required for fullScreen
  CircularProgress,
} from '@material-ui/core';
import { Save, ArrowBackIos } from '@material-ui/icons';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';

// import './CreateEntryDialog.css';
import { createEntryTC, getByExactDate } from '../store/entry';
import { isDemo } from '../services/DemoService';

class CreateEntryDialog extends Component {
  state = {};

  createEntry = async () => {
    if (!this.state.entry)
      return this.setState({ error: true, errorMessage: 'entry required' });

    let existingEntry = this.props.getByExactDate(
      this.props.user.id,
      this.state.date,
    );
    if (existingEntry)
      return this.setState({
        error: true,
        errorMessage: "today's entry already exist",
      });
    this.setState({ inProgress: true });
    await this.props.createEntry(
      this.state.entry,
      this.state.date,
      this.props.user.id,
    );
    this.setState({ entry: '', inProgress: false });
    const entry = this.props.getByExactDate(
      this.props.user.id,
      this.state.date,
    );
    if (this.props.onSaveComplete) this.props.onSaveComplete(entry);
  };

  handleDateChange = date => {
    this.setState({ date });
  };

  handleChange = key => event => {
    this.setState({
      [key]: event.target.value,
    });
  };

  onEntered = () => {
    this.setState({
      entry: '',
      date: new Date(this.props.date), // default to current page
      error: false,
      errorMessage: '', // string
      inProgress: false,
    });
    this.setState({
      date: new Date(this.props.date), // default to current page
    });
  };

  cancel = () => {
    this.props.onCancel();
  };

  render() {
    return (
      <Dialog
        fullScreen={this.props.fullScreen}
        open={this.props.open}
        onEntered={this.onEntered}
        onBackdropClick={this.cancel}
        disableBackdropClick={this.props.onboarding}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {this.props.onboarding ? 'Step 2: Add to your journal' : 'New Entry'}
        </DialogTitle>
        <DialogContent>
          {this.state.inProgress ? (
            <div className="overlay-2">
              <CircularProgress />
            </div>
          ) : null}
          {this.props.onboarding ? null : (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="flex-start">
                <DatePicker
                  margin="normal"
                  label="Date picker"
                  value={this.state.date}
                  onChange={this.handleDateChange}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          )}
          <DialogContentText>
            What did you do today? Who did you see? Where did you go?
          </DialogContentText>
          <TextField
            error={this.state.error}
            className="new-entry-text-field"
            label="new entry"
            multiline
            helperText={this.state.error ? this.state.errorMessage : ''}
            onChange={this.handleChange('entry')}
            value={this.state.entry}
            fullWidth
            variant="filled"
          />
        </DialogContent>
        <DialogActions>
          {this.props.onboarding ? null : (
            <Button onClick={this.cancel} color="primary">
              <ArrowBackIos /> CANCEL
            </Button>
          )}
          <Button disabled={isDemo()}
            onClick={this.createEntry} color="primary" autoFocus>
            <Save /> Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

CreateEntryDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  onboarding: PropTypes.bool,
  open: PropTypes.bool,
};

const mapDispatchToProps = dispatch => ({
  createEntry: (entry, date, user) =>
    dispatch(createEntryTC(entry, date, user)),
  getByExactDate: (userId, date) => getByExactDate(userId, date),
});

const mapStateToProps = state => ({ ...state });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withMobileDialog()(CreateEntryDialog));
