import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  withMobileDialog,
  CircularProgress,
  Dialog,
} from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { mapToWeekday } from '../services/Helpers';
import { AddPhotoAlternate, Save, ArrowBackIos } from '@material-ui/icons';

import './EntryUpdateForm.css';
import Images from './Images';
import { updateEntryTC } from '../store/entry';
import FireImage from '../firebase/FireImage';
import { isDemo } from '../services/DemoService';

class EntryUpdateForm extends Component {
  // TODO: make this component more modular. right now it touches editMenu and daily entry form
  state = {
    editEntryContent: '',
    files: [],
    inProgress: false,
  };

  constructor(props) {
    super(props);
    this.addPhotos = this.addPhotos.bind(this);
    this._uploadFile = this._uploadFile.bind(this);
    this.updateEntry = this.updateEntry.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.deselectEditEntry = this.deselectEditEntry.bind(this);
    this.state.editEntryContent = this.props.entry.content;
  }

  deselectEditEntry() {
    this.setState({ editEntryContent: this.props.entry.content });
    if (this.props.onClose) this.props.onClose();
    this.props.deselect();
    this.clearPhotos();
  }

  handleChange(event) {
    this.setState({
      editEntryContent: event.target.value,
    });
  }

  _initializeImage() {
    this.props.entry.images = [];
    this.props.entry.imageUrls = [];
  }

  async _appendInEntry(fireImage) {
    this.props.entry.images.push(fireImage.id);
    this.props.entry.imageUrls.push(await fireImage.getLink());
  }

  async _uploadFile(fileObject) {
    try {
      const id = uuid();
      const fireImage = new FireImage({
        entryId: this.props.entry.id,
        userId: this.props.user.id,
        id,
      });

      await fireImage.put(fileObject.file);
      await this._appendInEntry(fireImage);
    } catch (error) {
      console.error('upload failed', error);
    }
  }

  async _uploadFiles() {
    if (!this.props.entry.images) this._initializeImage();
    await Promise.all(this.state.files.map(this._uploadFile));
  }

  async updateEntry() {
    this.setState({ inProgress: true });
    if (this.state.files.length) await this._uploadFiles();
    await this.props
      .updateEntry(this.props.entry.id, {
        content: this.state.editEntryContent,
        images: this.props.entry.images || [],
        imageUrls: this.props.entry.imageUrls || [],
      })
      .then(this.deselectEditEntry);
    this.setState({ inProgress: false });
    this.onSaveComplete();
  }

  async onSaveComplete() {
    if (this.props.onSaveComplete) this.props.onSaveComplete();
  }

  async addPhotos(event) {
    const files = await this._formatFiles(event.target.files);
    this.setState({ files });
  }

  clearPhotos() {
    this.setState({ files: [] });
  }

  async _formatFiles(files) {
    const filesObjects = [...files];

    const readerResults = await Promise.all(
      filesObjects.map(
        fileObject =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(fileObject);
          }),
      ),
    );

    return filesObjects.map((file, index) => ({
      file,
      readerResult: readerResults[index],
    }));
  }

  render() {
    return (
      <Dialog
        fullScreen={this.props.fullScreen}
        open={this.props.selected || this.props.onboarding || false}
        onClose={this.handleClose}
        disableBackdropClick={this.props.onboarding}
        aria-labelledby="responsive-dialog-title"
      >
        <div
          className={`dialog-body ${this.props.fullScreen ? '' : 'fullscreen'}`}
        >
          <DialogTitle id="responsive-dialog-title">
            {this.props.onboarding
              ? 'Step 3: Add a Photo'
              : `${mapToWeekday(this.props.entry.day)}, ${this.props.entry
                .month + 1} / ${this.props.entry.date} / ${
              this.props.entry.year
              }`}
          </DialogTitle>
          <DialogContent>
            <div>
              {this.state.inProgress ? (
                <div className="overlay-2">
                  <CircularProgress />
                </div>
              ) : null}
              <Images files={this.state.files} entry={this.props.entry} />
              <TextField
                value={this.state.editEntryContent}
                label={this.props.entry.year}
                variant="outlined"
                onChange={this.handleChange}
                helperText="What have you done today?"
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
                multiline
              />
              <div className="edit-buttons-container">
                {this.props.onboarding ? null : (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={this.deselectEditEntry}
                  >
                    <ArrowBackIos /> CANCEL
                  </Button>
                )}
                <label htmlFor="contained-button-file">
                  <Button
                    variant="contained"
                    component="span"
                    color={
                      this.props.onboarding && !this.state.files.length
                        ? 'primary'
                        : 'default'
                    }
                  >
                    <AddPhotoAlternate /> UPLOAD
                  </Button>
                </label>
                <input
                  accept="image/*"
                  id="contained-button-file"
                  className="picture"
                  multiple
                  type="file"
                  onChange={this.addPhotos}
                />
                {!this.props.onboarding || this.state.files.length ? (
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={this.updateEntry}
                    disabled={isDemo()}
                  >
                    <Save /> SAVE
                  </Button>
                ) : null}
              </div>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    );
  }
}

EntryUpdateForm.propTypes = {
  onboarding: PropTypes.bool,
  onSaveComplete: PropTypes.func,
  onboardingStep: PropTypes.number,
};

const mapDispatchToProps = dispatch => ({
  updateEntry: (id, updates) => dispatch(updateEntryTC(id, updates)),
});

const mapStateToProps = state => ({ ...state });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withMobileDialog()(EntryUpdateForm));
