import React, { Component } from 'react';
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import Firebase, { authentication } from '../firebase/firebase';
import firebaseui from 'firebaseui';

import './LogIn.css';

let ui;

class LogIn extends Component {
  componentDidMount() {
    const uiConfig = {
      signInSuccessUrl: '/page',
      signInOptions: [
        Firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      ],
    };
    if (!ui) {
      ui = new firebaseui.auth.AuthUI(authentication);
      ui.start('#firebaseui-auth-container', uiConfig);
    }
  }
  render() {
    return (
      <div className="LogIn">
        <div className="journal-cover">
          <div className="logo-container">
            <img className="logo" src="./pen-icon.png" alt="" />
          </div>
          <h1>Daily Memories</h1>
          {/* <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={authentication}></StyledFirebaseAuth> */}
          <div id="firebaseui-auth-container" />
        </div>
      </div>
    );
  }
}

const mapLogin = state => ({ ...state });

const mapDispatch = dispatch => ({});

export default connect(
  mapLogin,
  mapDispatch,
)(LogIn);

// LogIn.propTypes = {
//   handleSubmit: PropTypes.func.isRequired,
// };
