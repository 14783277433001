import Firebase from 'firebase';
import * as configs from './configs';
import Logger from '../services/Log';

const logger = new Logger('FIREBASE') as any;

const isProduction = true ||
  window.location.hostname === 'daily-memories.com' ||
  window.location.hostname === 'five-year.firebaseapp.com';

let config = isProduction ? configs.production : configs.staging;
logger.debug(`using ${config.projectId}`);

export const firebaseApp = Firebase.initializeApp(config);
export const firestore = firebaseApp.firestore();
export const fireStorage = firebaseApp.storage();
firestore.settings({ timestampsInSnapshots: true });
export const authentication = firebaseApp.auth();
export { config };
export default Firebase;
