import React, { Component } from 'react';
import { connect } from 'react-redux';
import { List, ListItem } from '@material-ui/core';

import Controls from './Controls';
import DailyEntry from './DailyEntry';
import { getEntriesTC, getByExactDate } from '../store/entry';
import NewEntryDialog from './NewEntryDialog';
import OnBoard from './OnBoard';
import LookBackMethodHelpText from './LookBackMethodHelpText';
import './Page.css';
import { isDemo } from '../services/DemoService';

const HIDE_ONBOARD_PERIOD = 1000 * 60 * 60 * 24 * 60; // 60 days
const NEW_USER_PERIOD = 1000 * 60 * 60; // 1 hour

class Page extends Component {
  componentDidMount() {
    if (this.props.user.id) this.loadUserData(new Date());
  }

  loadUserData(date) {
    this.props.getEntries(this.props.user, date);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.date !== this.props.date) {
      if (this.props.user.id) {
        this.loadUserData(this.props.date);
      }
    }
  }

  _signedLongAgo = () =>
    !this.props.user.lastUpdated ||
    new Date().getTime() - this.props.user.lastUpdated.toMillis() >
    HIDE_ONBOARD_PERIOD;

  _isSignedIn = () => !!this.props.user.id;

  _hasEntryForCurrentDate = () =>
    !!this.props.getByExactDate(this.props.user.id, new Date());

  _isRecentlyCreated = () => {
    const timeSinceCreated =
      new Date() - new Date(this.props.user.creationTime);
    return timeSinceCreated < NEW_USER_PERIOD;
  };

  _storedShouldShowOnboard;
  /**
   * checks of if should start onboarding. Once it's true can't be set to false until reload
   * we don't want to stop onboarding once a entry is created, we want to wait until the end
   * of onboarding.
   */
  shouldShowOnboard = () => {
    if (!this._storedShouldShowOnboard) {
      this._storedShouldShowOnboard =
        !isDemo() &&
        this._isSignedIn() &&
        (this._signedLongAgo() || this._isRecentlyCreated()) &&
        !this._hasEntryForCurrentDate();
    }
    return this._storedShouldShowOnboard;
  };

  render() {
    return (
      <div className="Page">
        <Controls />
        <List style={{ overflow: 'auto' }}>
          <LookBackMethodHelpText />
          {this.props.entries.map(entry => {
            return entry.content ? (
              <ListItem key={entry.id} disableGutters={false}>
                <DailyEntry entry={entry} />
              </ListItem>
            ) : null;
          })}
        </List>
        <div className="gap" />
        <NewEntryDialog />
        {this.shouldShowOnboard() ? <OnBoard /> : null}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getEntries: (user, date) => dispatch(getEntriesTC(user, date)),
  getByExactDate: (userId, date) => getByExactDate(userId, date),
});

const mapStateToProps = state => ({ ...state });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Page);
