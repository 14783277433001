import { Reducer } from 'redux';

/**
 * ACTION TYPES
 */
const SET_DATE = 'SET_DATE';

/**
 * ACTION CREATORS
 */

/**
 * sets date on store
 * @param {Date} date 
 */
export const setDate = (date: Date) => ({ type: SET_DATE, date });

/**
 * REDUCER
 */
const reducer: Reducer = (date = new Date(), action) => {
  switch (action.type) {
    case SET_DATE:
      return action.date;

    default:
      return date;
  }
}


export default reducer