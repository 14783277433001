import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IconButton } from '@material-ui/core';
import { PlayArrow, Today, CloudDownload } from '@material-ui/icons';

import { setDate } from '../store/date';
import Menu from './Menu';
import { getEntriesTC } from '../store/entry';
import './Controls.css';
import { mapToWeekday } from '../services/Helpers';
import Logger from '../services/Log';
import { isDemo } from '../services/DemoService';

const logger = new Logger('CONTROLS');

class Controls extends Component {
  state = { addToHomePrompt: null };
  constructor(props) {
    super(props);
    if (this.shouldSetupAddToHome()) this.setupAddToHome();
  }

  shouldSetupAddToHome = () => {
    return (
      !window.matchMedia('(display-mode: standalone)').matches &&
      !window.navigator.standalone === true
    );
  };
  setupAddToHome() {
    logger.debug('setupAddToHome');

    window.addEventListener('beforeinstallprompt', e => {
      logger.debug('beforeinstallprompt');
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      this.setState({ addToHomePrompt: e });
      logger.debug('beforeinstallprompt');
    });

    window.addEventListener('appinstalled', evt => { });
  }

  addToHome = () => {
    this.state.addToHomePrompt.prompt();
    this.state.addToHomePrompt.userChoice.then(choiceResult => {
      if (choiceResult.outcome === 'accepted')
        logger.debug('User accepted the A2HS prompt');
      else logger.debug('User dismissed the A2HS prompt');
    });
    this.setState({ addToHomePrompt: null });
  };

  goToToday = () => {
    let today = new Date();
    this.props.setDate(today);
  };

  backOneDay = () => {
    const dateValue = this.props.date.valueOf();
    let yesterday = new Date(dateValue - 8.64e7);
    this.props.setDate(yesterday);
  };

  forwardOneDay = () => {
    const dateValue = this.props.date.valueOf();
    let tomorrow = new Date(dateValue + 8.64e7);
    this.props.setDate(tomorrow);
  };

  render() {
    const _isDemo = isDemo();
    return (
      <div className="Controls">
        <IconButton disabled={_isDemo}
          aria-label="Back One Day" onClick={this.backOneDay}>
          <PlayArrow className="flip-direction" fontSize="small" />
        </IconButton>
        <span className="date">
          Date: {mapToWeekday(this.props.date.getDay())},{' '}
          {this.props.date.getMonth() + 1} / {this.props.date.getDate()}
        </span>
        <IconButton disabled={_isDemo}
          aria-label="Forward One Day" onClick={this.forwardOneDay}>
          <PlayArrow fontSize="small" />
        </IconButton>
        <IconButton disabled={_isDemo}
          aria-label="Today" onClick={this.goToToday}>
          <Today fontSize="small" />
        </IconButton>
        {this.state.addToHomePrompt ? (
          <IconButton disabled={_isDemo}
            aria-label="Today" onClick={this.addToHome}>
            <CloudDownload fontSize="small" color="primary" />
          </IconButton>
        ) : null}
        <Menu />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setDate: date => dispatch(setDate(date)),
  getEntries: (user, date) => dispatch(getEntriesTC(user, date)),
});

const mapStateToProps = state => ({ ...state });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Controls);
