import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Typography, Card, CardHeader, CardContent } from '@material-ui/core';
import PropTypes from 'prop-types';

import './DailyEntry.css';
import EntryUpdateForm from './EntryUpdateForm';
import EntryMenu from './EntryMenu';
import { getOffset } from '../services/Helpers';

class DailyEntry extends Component {
  state = {
    selected: false,
  };

  handleSelect = () => {
    this.setState({ selected: true });
  };

  handleDeselect = () => {
    this.setState({ selected: false });
  };

  getInitialState() {
    return { file: [] };
  }

  generateTitle() {
    const { offsetInteger, offsetUnit } = getOffset(
      this.props.user.lookupMethod,
      this.props.entry,
      this.props.date,
    );
    return offsetInteger === 0
      ? 'Today'
      : `${offsetInteger} ${offsetUnit}${offsetInteger === 1 ? '' : 's'} ago`;
  }

  render() {
    return (
      <Card className="DailyEntry">
        <CardHeader
          className="card-header"
          title={this.generateTitle()}
          action={
            <EntryMenu entry={this.props.entry} select={this.handleSelect} />
          }
        />
        {this.props.entry.images && this.props.entry.images.length ? (
          <Carousel
            showIndicators={false}
            showThumbs={false}
            dynamicHeight={true}
          >
            {this.props.entry.images.map((id, index) => (
              <div key={id}>
                <img
                  loading="lazy"
                  src={this.props.entry.imageUrls[index]}
                  alt={this.props.entry.imageUrls[index]}
                />
              </div>
            ))}
          </Carousel>
        ) : null}
        <CardContent className="card-content">
          <EntryUpdateForm
            selected={this.state.selected}
            deselect={this.handleDeselect}
            entry={this.props.entry}
            onboarding={this.props.onboarding}
          />
          <Typography component="p" className="entry-text">
            {this.props.entry.content}
          </Typography>
        </CardContent>
      </Card>
    );
  }
}

DailyEntry.propTypes = {
  onboarding: PropTypes.bool,
};

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => ({ ...state });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DailyEntry);
