import Logger from '../services/Log';
import { IEntry, IConfig } from './entry';

const logger = new Logger('LOCAL ENTRY');

export default class LocalEntry {
  static _EXPIRATION_INTERVAL = 1000 * 60 * 60; // 1 hour

  static fetchDate() {
    return window.localStorage.lastFetched
      ? new Date(window.localStorage.lastFetched)
      : new Date(0);
  }

  /**
   * store entry locally with unique key
   * @param {number} date
   */
  static store(entry: IEntry) {
    let key = `five_year/${entry.user}/entries/${entry.date}/${entry.month}/${
      entry.year
      }`;
    window.localStorage[key] = JSON.stringify(entry);
  }

  static get isExpired() {
    return (new Date()).getTime() - this.fetchDate().getTime() > this._EXPIRATION_INTERVAL;
  }

  static _getKey(userId: string, date: number, month: number, year: number) {
    return `five_year/${userId}/entries/${date}/${month}/${year}`;
  }

  static _getLocalEntry(userId: string, date: number, month: number, year: number) {
    let key = this._getKey(userId, date, month, year);
    return window.localStorage[key];
  }

  static updateFetchDate() {
    window.localStorage.lastFetched = new Date().toString();
  }

  // TODO: move to own class.
  static getByConfig(config: IConfig) {
    const userId = config.user;
    // start 1 year forward
    const yearLatest = new Date().getFullYear() + 1;

    /**
     * get from window.localStorage
     */
    function getByDate(d: Date) {
      const date = d.getDate()
      logger.debug('getting by date');
      let entries = [];
      // look back 20 years
      for (let year = yearLatest; year > yearLatest - 20; year--) {
        for (let month = 11; month >= 0; month--) {
          let localEntry = LocalEntry._getLocalEntry(userId, date, month, year);
          if (localEntry) entries.push(JSON.parse(localEntry));
        }
      }
      return entries;
    }

    /**
     * get from window.localStorage by date and month
     */
    function getByMonthAndDate(d: Date) {
      const date = d.getDate()
      const month = d.getMonth();
      logger.debug('getting by date and month');
      let entries = [];
      // look back 20 years
      for (let year = yearLatest; year > yearLatest - 20; year--) {
        let localEntry = LocalEntry._getLocalEntry(userId, date, month, year);
        if (localEntry) entries.push(JSON.parse(localEntry));
      }
      return entries;
    }

    /**
     * get from window.localStorage by day of the week
     */
    function getByDay(d: Date) {
      const day = d.getDay()
      logger.debug('getting by day', day);
      let entries = [];
      const today = new Date();
      const startingDate = new Date();
      const offset = today.getDay() - day;
      // start 2 years in the future
      startingDate.setDate(today.getDate() - offset + 7 * 52 * 2);
      console.log(startingDate);
      const FIVE_YEARS_IN_THE_PAST = 1000 * 60 * 60 * 24 * 365 * 5;


      for (
        let oneWeekAgo = startingDate;
        today.getTime() - oneWeekAgo.getTime() < FIVE_YEARS_IN_THE_PAST;
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)
      ) {
        let [date, month, year] = [
          oneWeekAgo.getDate(),
          oneWeekAgo.getMonth(),
          oneWeekAgo.getFullYear(),
        ];
        let localEntry = LocalEntry._getLocalEntry(userId, date, month, year);
        if (localEntry) entries.push(JSON.parse(localEntry));
      }
      console.log({ endingDate: startingDate });

      return entries;
    }

    /**
     * different from getByDate. this one is specific for date and year
     */
    function getByExactDate(d: Date) {
      const date = d.getDate()
      const month = d.getMonth();
      const year = d.getFullYear()
      let localEntry = LocalEntry._getLocalEntry(userId, date, month, year);
      return localEntry === undefined ? [] : [JSON.parse(localEntry)];
    }

    function getByBenchmark(d: Date) {
      const date = d.getDate()
      const month = d.getMonth();
      const year = d.getFullYear()
      const dCopy = () => new Date(d);
      return [
        ...getByExactDate(d), // today
        ...getByExactDate(new Date(dCopy().setDate(date - 7))), // last week
        ...getByExactDate(new Date(dCopy().setMonth(month - 1))), // last month
        ...getByExactDate(new Date(dCopy().setMonth(month - 2))), // 2 months ago
        ...getByExactDate(new Date(dCopy().setMonth(month - 3))), // 3 months ago
        ...getByExactDate(new Date(dCopy().setMonth(month - 4))), // 6 months ago
        ...getByExactDate(new Date(dCopy().setFullYear(year - 1))), // 1 year ago
        ...getByExactDate(new Date(dCopy().setFullYear(year - 2))), // 2 years ago
        ...getByExactDate(new Date(dCopy().setFullYear(year - 3))), // 3 years ago
        ...getByExactDate(new Date(dCopy().setFullYear(year - 4))), // 4 years ago
        ...getByExactDate(new Date(dCopy().setFullYear(year - 5))), // 5 years ago
      ];
    }

    switch (config.lookupMethod) {
      case 'exact':
        return getByExactDate(config.date);
      case 'benchmark':
        return getByBenchmark(config.date);
      case 'day':
        return getByDay(config.date);
      case 'date':
        return getByDate(config.date);
      case 'date_and_month':
        return getByMonthAndDate(config.date);
    }
  }

  static delete(remoteEntry: any) {
    let key = LocalEntry._getKey(
      remoteEntry.user,
      remoteEntry.date,
      remoteEntry.month,
      remoteEntry.year,
    );
    window.localStorage.removeItem(key);
  }
}
