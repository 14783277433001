import { IEntry } from '../store/entry';
import { TLookupMethod } from '../store/auth';

export const mapToWeekday = (day: number) => {
  return ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'][day];
};

interface IOffset {
  offsetInteger: number;
  offsetUnit: string;
}


/**
 * Gets the time difference between page date and entry date.
 */
export function getOffset(lookupMethod: TLookupMethod, entry: IEntry, date: Date): IOffset {
  console.log(lookupMethod);

  switch (lookupMethod) {
    case 'benchmark':
      return _getOffsetForBenchmark(entry, date);
    case 'day':
      return _getOffsetForWeek(entry, date);
    case 'date_and_month':
      return _getOffsetForYear(entry, date);
    case 'date':
      return _getOffsetForMonth(entry, date);
    default:
      throw new Error();
  }
}

/**
 * assumes offset by years if it's greater than a year,
 * assumes offset by months if it's greater than a month,
 * assumes offset by weeks if it's greater than 6 days,
 * else assumes offset by days. 
 */
function _getOffsetForBenchmark(entry: IEntry, date: Date): IOffset {
  const A_DAY = 1000 * 60 * 60 * 24;
  // make the months smaller to have a round down affect
  const A_MONTH = A_DAY * 29;
  const A_YEAR = A_MONTH * 12;
  if (date.getTime() - new Date(entry.dateString).getTime() >= A_YEAR) {
    return _getOffsetForYear(entry, date)
  } else if (date.getTime() - new Date(entry.dateString).getTime() >= A_MONTH) {
    return _getOffsetForMonth(entry, date)
  } else if (entry.date !== date.getDate()) {
    if ((date.getTime() - new Date(entry.dateString).getTime()) > 6 * A_DAY) {
      return _getOffsetForWeek(entry, date)
    }
    return _getOffsetForDay(entry, date)
  } else if (entry.date === date.getDate()) {
    return _getOffsetForDay(entry, date)
  } else {
    throw new Error('can\'t find offset date');
  }
}

function _getOffsetForWeek(entry: IEntry, date: Date): IOffset {
  const timeDifferenceMs = (date.getTime() - (new Date(entry.dateString)).getTime());
  const timeDifferenceDays = _msToDays(timeDifferenceMs);
  const timeDifferenceWeeks = _daysToWeeks(timeDifferenceDays)
  return {
    offsetInteger: timeDifferenceWeeks, offsetUnit: 'week'
  };
}

function _getOffsetForYear(entry: IEntry, date: Date): IOffset {
  return { offsetInteger: date.getFullYear() - entry.year, offsetUnit: 'year' };
}

function _getOffsetForMonth(entry: IEntry, date: Date): IOffset {
  const timeDifferenceMs = (date.getTime() - (new Date(entry.dateString)).getTime());
  const timeDifferenceDays = _msToDays(timeDifferenceMs);
  const timeDifferenceMonths = _daysToMonths(timeDifferenceDays)
  return { offsetInteger: timeDifferenceMonths, offsetUnit: 'month' };
}

function _getOffsetForDay(entry: IEntry, date: Date): IOffset {
  const timeDifferenceMs = (date.getTime() - (new Date(entry.dateString)).getTime());
  const timeDifferenceDays = _msToDays(timeDifferenceMs);
  return { offsetInteger: timeDifferenceDays, offsetUnit: 'day' };
}

function _msToDays(ms: number): number {
  return Math.round(ms / 1000 / 60 / 60 / 24);
}

/**
 * Converts days to months. assumes days is close to months and only returns integers
 */
function _daysToMonths(days: number): number {
  return Math.round(days / 30.44);
}

/**
 * Converts days to weeks. assumes days is close to months and only returns integers
 */
function _daysToWeeks(days: number): number {
  return Math.round(days / 7);
}
