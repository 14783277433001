import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Router, Redirect } from 'react-router';
import { connect } from 'react-redux';

import Page from './Page';
import LogIn from './LogIn';
import { setDate } from '../store/date';
import { createUserTC, getUserTC } from '../store/auth';
import { getEntriesTC } from '../store/entry';
import { authentication } from '../firebase/firebase';
import Logger from '../services/Log';

import './App.css';
import { DEMO_USER_ID, isDemo } from '../services/DemoService';
import { history } from '../services/HistoryService';

const _logger = new Logger('APP');

class App extends Component {
  componentDidMount = async () => {
    // demo
    if (isDemo()) {
      await this.props.getUser(DEMO_USER_ID);
      _logger.log('using demo account');
      await this.props.setDate(new Date(2019, 9, 14));
      return;
    }

    this.props.setDate(new Date());
    authentication.onAuthStateChanged(async user => {
      if (user) {
        _logger.log('logged in', { user });
        // TODO: move this to a user class
        await this.props.getUser(user.uid);
        if (!this.props.user || !this.props.user.creationTime)
          // first time log on
          await this.props.createUser(user);
        this.props.getEntries(this.props.user, new Date());
      } else {
        _logger.debug('not logged in');
        if (history.location.pathname !== '/login') history.push('/login');
      }
    });
  };

  componentDidUpdate(prevProps) {
    if (isDemo()) {
      return;
    }

    if (this.props.user.id && prevProps.user !== this.props.user) {
      history.push('/page');
    }
  }

  render() {
    return (
      <Router history={history}>
        <div className="App">
          <Switch>
            <Route path="/login" component={LogIn} />
            <Route path="/demo" component={Page} />
            <Route path="/page" component={Page} />
            <Redirect from="/" to="/login" />
          </Switch>
        </div>
      </Router>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setDate: date => dispatch(setDate(date)),
  createUser: user => dispatch(createUserTC(user)),
  getUser: id => dispatch(getUserTC(id)),
  getEntries: (user, date) => dispatch(getEntriesTC(user, date)),
});

const mapStateToProps = state => ({ ...state });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
