import React, { Component } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  withMobileDialog, // required for fullScreen
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { patchUserTC } from '../store/auth';
import './OnBoard.css';
import NewEntryDialog from './NewEntryDialog';
import { getByExactDate } from '../store/entry';

/**
 * This component is show when a user is new or haven't been on in a while.
 */
class OnBoard extends Component {
  state = {
    open: true,
    step: 0,
    entry: null,
  };

  close = () => this.setState({ open: false });
  next = () => this.setState({ step: this.state.step + 1 });

  step0View = () => (
    <div className={`dialog-body ${this.props.fullScreen ? '' : 'fullscreen'}`}>
      <DialogTitle>Welcome to your daily memories!</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          As you write more entries, you'll notice past entries begin to appear
          in your feed.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.next} color="primary" autoFocus>
          Next
        </Button>
      </DialogActions>
    </div>
  );

  step1View = () => (
    <div className={`dialog-body ${this.props.fullScreen ? '' : 'fullscreen'}`}>
      <DialogTitle>Step 1:</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Create a new journal entry.
        </DialogContentText>
      </DialogContent>
      <DialogActions className="onboard-actions">
        <Button onClick={this.close} color="primary">
          Skip
        </Button>
        {/* step 2 is inside NewEntryDialog */}
        <NewEntryDialog onboarding={true} onSaveComplete={this.next} />
      </DialogActions>
    </div>
  );

  step2View = () => {
    return (
      <div
        className={`dialog-body ${this.props.fullScreen ? '' : 'fullscreen'}`}
      >
        <DialogTitle>You're all Done!</DialogTitle>
        <DialogActions>
          <Button onClick={this.close} color="primary">
            Close
          </Button>
        </DialogActions>
      </div>
    );
  };

  render = () => (
    <div className="OnBoarding">
      <Dialog
        fullScreen={this.props.fullScreen}
        maxWidth="sm"
        open={this.state.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {(() => {
          switch (this.state.step) {
            case 0:
              return this.step0View();
            case 1:
              return this.step1View();
            case 2:
              return this.step2View();
            default:
              return this.step0View();
          }
        })()}
      </Dialog>
    </div>
  );
}

OnBoard.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

const mapDispatchToProps = dispatch => ({
  patchUser: fields => dispatch(patchUserTC(fields)),
  getByExactDate: (user, date, lookupMethod) =>
    getByExactDate(user, date, lookupMethod),
});

const mapStateToProps = state => ({ ...state });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withMobileDialog()(OnBoard));
