import React, { Component } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core/';
import { MoreVert } from '@material-ui/icons';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { updateEntryTC, deleteEntryTC } from '../store/entry';

class EntryMenu extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  select = () => {
    this.handleClose();
    this.props.select();
  };

  handleClose = () => this.setState({ anchorEl: null });

  deleteEntry = async () => {
    await this.props.deleteEntry(this.props.entry.id);
    this.handleClose();
  };

  render = () => {
    return (
      <div>
        <IconButton
          aria-owns={this.state.anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
        >
          {window.localStorage.dev ? (
            <MenuItem onClick={this.deleteEntry}>Dev Delete</MenuItem>
          ) : null}
          <MenuItem
            onClick={this.select}>Edit</MenuItem>
        </Menu>
      </div>
    );
  };
}

EntryMenu.propTypes = {
  open: PropTypes.bool,
  entry: PropTypes.object,
  select: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  updateEntry: (id, updates) => dispatch(updateEntryTC(id, updates)),
  deleteEntry: id => dispatch(deleteEntryTC(id)),
});

const mapStateToProps = state => ({ ...state });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EntryMenu);
