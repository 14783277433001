export const production = {
  apiKey: 'AIzaSyA_q6VW3mmTulKUgWp-_dwxmT7bqkulUQI',
  authDomain: 'app.daily-memories.com',
  databaseURL: 'https://five-year.firebaseio.com',
  projectId: 'five-year',
  storageBucket: 'five-year.appspot.com',
  messagingSenderId: '809378546470',
};

export const staging = {
  apiKey: 'AIzaSyBFMS5GniLCB-92EzSPC57-2pqFkeQY9-A',
  authDomain: 'five-year-staging.firebaseapp.com',
  databaseURL: 'https://five-year-staging.firebaseio.com',
  projectId: 'five-year-staging',
  storageBucket: 'five-year-staging.appspot.com',
  messagingSenderId: '633890966388',
};
