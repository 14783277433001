import React, { Component } from 'react';
import { connect } from 'react-redux';

import './Images.css';
import { Delete, ArrowBackIos } from '@material-ui/icons';
import { updateEntryTC } from '../store/entry';
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Button,
  withMobileDialog,
} from '@material-ui/core';
import FireImage from '../firebase/FireImage';

class Images extends Component {
  state = {
    open: false,
    selectedImageIndex: null,
  };

  deselectImage = () => {
    this.setState({
      selectedImageIndex: null,
      open: false,
    });
  };

  deleteImage = async () => {
    await Promise.all([this._deleteFromStorage(), this._removeDbPointer()]);
    this.deselectImage();
  };

  _removeDbPointer = async () => {
    const newImages = [...this.props.entry.images];
    newImages.splice(this.state.selectedImageIndex, 1);
    const newImageUrls = [...this.props.entry.imageUrls];
    newImageUrls.splice(this.state.selectedImageIndex, 1);
    return await this.props.updateEntry(this.props.entry.id, {
      images: newImages,
      imageUrls: newImageUrls,
    });
  };
  _deleteFromStorage = async () => {
    const id = this.props.entry.images[this.state.selectedImageIndex];
    const image = new FireImage({
      entryId: this.props.entry.id,
      userId: this.props.user.id,
      id,
    });
    return await image.delete();
  };

  selectImage = index => {
    this.setState({
      selectedImageIndex: index,
      open: true,
    });
  };

  render() {
    return (
      <div className="Images">
        {/* existing images */}
        {this.props.entry.imageUrls &&
          this.props.entry.imageUrls.map((url, index) => (
            <div
              key={url}
              style={{ backgroundImage: `url(${url})` }}
              className="tile"
              onClick={() => this.selectImage(index)}
            />
          ))}
        {/* image to upload */}
        {this.props.files.map(fileObject => (
          <div
            key={fileObject.file.name}
            style={{ backgroundImage: `url(${fileObject.readerResult})` }}
            className="tile"
          />
        ))}
        <Dialog
          fullScreen={this.props.fullScreen}
          open={this.state.open}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">Delete Photo</DialogTitle>
          <img
            className="selectedImage"
            src={this.props.entry.imageUrls[this.state.selectedImageIndex]}
            alt={this.props.entry.imageUrls[this.state.selectedImageIndex]}
          />
          <DialogContent>
            <DialogContentText>
              Are you sure? This photo will be deleted permanently.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div className="button-container">
              <Button
                variant="contained"
                size="small"
                onClick={this.deselectImage}
              >
                <ArrowBackIos /> cancel
              </Button>
              <Button
                variant="contained"
                component="span"
                color="secondary"
                onClick={this.deleteImage}
              >
                <Delete /> delete
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateEntry: (id, updates) => dispatch(updateEntryTC(id, updates)),
});

const mapStateToProps = state => ({ ...state });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withMobileDialog()(Images));
