// from here: https://medium.com/backticks-tildes/setting-up-a-redux-project-with-create-react-app-e363ab2329b8
import { createStore, applyMiddleware, combineReducers } from 'redux';
// TODO: IMPLEMENT LOGGER
// import createLogger from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
// import simple from './simple'
import entries from './entry';
import date from './date';
import user from './auth';

const rootReducer = combineReducers({ entries, date, user });

// const middleware = applyMiddleware(thunkMiddleware, createLogger({ collapse: true }));

export default function configureStore(initialState = {}) {
  return createStore(rootReducer, applyMiddleware(thunkMiddleware));
}
