import FireEntryModel from '../firebase/FireEntriesModel';
import RemoteEntry from './RemoteEntry';
import LocalEntry from './LocalEntry';
import Logger from '../services/Log';
import FireUserModel from '../firebase/FireUsersModel';
import FireImage from '../firebase/FireImage';
import { patchUserTC, TLookupMethod } from './auth';
import { Dispatch, AnyAction, Reducer, ActionCreator } from 'redux';
import { setDate } from './date';

const logger: any = new Logger('ENTRY STORE');

/**
 * ACTION TYPES
 */
const GET_ENTRIES = 'GET_ENTRIES';
const UPDATE_ENTRY = 'UPDATE_ENTRY';
const DELETE_ENTRY = 'DELETE_ENTRY';

export interface IEntry {
  content: string;
  date: number;
  day: number;
  demo?: boolean;
  imageUrls: string[];
  images: string[];
  month: number;
  user: string;
  year: number;
  id: string;
  dateString: string;
}

interface IAction extends AnyAction {
  type: string;
  entries?: IEntry[];
  entry?: IEntry;
  id?: string;
}

const getEntriesAction: ActionCreator<IAction> = (entries: IEntry[]): IAction => ({ type: GET_ENTRIES, entries });
const deleteEntryAction: ActionCreator<IAction> = (id: string): IAction => ({ type: DELETE_ENTRY, id });
const updateEntryAction: ActionCreator<IAction> = (entry: IEntry): IAction => ({ type: UPDATE_ENTRY, entry });

/**
 * REDUCER
 */
const reducer: Reducer = (entries: IEntry[] = [], action: AnyAction) => {
  switch (action.type) {
    case GET_ENTRIES:
      return action.entries;

    case UPDATE_ENTRY:
      return entries.map(
        entry => (action.entry.id === entry.id ? action.entry : entry),
      );

    case DELETE_ENTRY:
      return entries.filter(entry => entry.id !== action.id);

    default:
      return entries;
  }
}

/**
 * THUNK CREATORS
 */

/**
 *
 * @param {object} user
 * @param {Date} date
 */
export const getEntriesTC = (user: any, date: Date) => async (dispatch: Dispatch) => {
  logger.debug('getting entries', { user, date });
  let entries = await getEntries(user, date);
  logger.debug('local entries', entries);
  dispatch(getEntriesAction(entries));
};

/**
 * helper function, does not update state
 * @param {object} config
 */
export const getEntries = async (user: any, date: Date) => {
  const byDate = (entryA: any, entryB: any): any => {
    return (
      (new Date(entryB.year, entryB.month, entryB.date) as any) -
      (new Date(entryA.year, entryA.month, entryA.date) as any)
    );
  };
  const config = createLookupConfig(user, date);

  if (LocalEntry.isExpired) await resyncEntriesTC(config.user);
  logger.debug('get entries', { config });
  const entries = LocalEntry.getByConfig(config) as IEntry[];
  return entries.sort(byDate);
};


export interface IConfig {
  user: string;
  date: Date;
  lookupMethod: TLookupMethod;
}

const createLookupConfig = (user: any, date: Date): IConfig => {
  return {
    user: user.id,
    date,
    lookupMethod: user.lookupMethod,
  };
};

/**
 * pull remote entries and updates local
 */
export const resyncEntriesTC = async (userId: string, force: boolean = false) => {
  logger.debug('resyncing all from remote...');
  let remoteEntries = null;
  if (force || LocalEntry.isExpired) {
    remoteEntries = await RemoteEntry.getByConfig({ user: userId });
    logger.debug('got remote entries', remoteEntries);
    LocalEntry.updateFetchDate();
    if (remoteEntries)
      remoteEntries.forEach(LocalEntry.store);
    logger.debug('all local updated');
  }
  return remoteEntries;
};

/**
 * update one entry
 */
export const updateEntryTC = (id: string, updatedFields: Partial<IEntry>) => async (dispatch: Dispatch) => {
  try {
    let entryModel = new FireEntryModel({ id });
    let entry = await entryModel.patch(updatedFields);
    LocalEntry.store(entry);
    // update time stamp
    dispatch(updateEntryAction(entry));
    patchUserTC();
  } catch (error) {
    logger.error('fetching entries unsuccessful', error);
  }
};

/**
 * create a new entry
 * @param {string} content
 * @param {Date} date required
 * @param {string} userId
 * @param {string[]} images ids
 * @param {string[]} imageUrls urls
 */
export const createEntryTC = (
  content: string,
  date: Date,
  userId: string,
  images = [],
  imageUrls = [],
) => async (dispatch: Dispatch) => {
  const newEntry = new FireEntryModel({
    user: userId,
    day: date.getDay(),
    date: date.getDate(),
    month: date.getMonth(),
    year: date.getFullYear(),
    content,
    images,
    imageUrls,
  });
  try {
    let entry = await newEntry.put();
    LocalEntry.store(entry);
    const fireUser = await new FireUserModel().getById(entry.user);
    await Promise.all([
      // update time stamp
      patchUserTC(),
      getEntriesTC(fireUser, date)
    ]);
    // triggers state update shown entries
    dispatch(setDate(date));
  } catch (error) {
    logger.error('unable to create entry', error);
  }
};

export const deleteEntryTC = (id: string) => async (dispatch: Dispatch) => {
  const fireEntryModel = new FireEntryModel({ id });
  try {
    const remoteEntry = await fireEntryModel.get();
    await Promise.all(
      remoteEntry.images.map((id: string) =>
        _deleteImage(id, remoteEntry.user, remoteEntry.id),
      ),
    );
    await fireEntryModel.delete();
    LocalEntry.delete(remoteEntry);
    dispatch(deleteEntryAction(remoteEntry.id));
    // update time stamp
    patchUserTC();
  } catch (error) {
    logger.error('unable to delete entry', error);
  }
};

const _deleteImage = async (id: string, userId: string, entryId: string) => {
  const fireImage = new FireImage({ userId, id, entryId });
  return await fireImage.delete();
};

/**
 * check by exact date
 * @param {object} config
 * @returns {boolean} exists
 */
export const getByExactDate = (userId: string, date: Date) => {
  const config: IConfig = {
    user: userId,
    date,
    lookupMethod: 'exact'
  };
  const entries = LocalEntry.getByConfig(config);
  return entries ? entries[0] : [];
};

export default reducer;
