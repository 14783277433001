import FireUsersModel from '../firebase/FireUsersModel';
import Logger from '../services/Log';

const logger = new Logger('AUTH STORE');
let userId: string | null;

/**
 * ACTION TYPES
 */
const SET_USER = 'SET_USER';

/**
 * ACTION CREATORS
 */
//  patch and set have the same action
const setUser = (user: IUser | null) => ({ type: SET_USER, user });

export type TLookupMethod = 'day' | 'date' | 'date_and_month' | 'benchmark' | 'exact'

export interface IUser {
  creationTime?: Date;
  lastUpdated?: Date;
  email: string;
  id: string;
  onboarded: boolean;
  lookupMethod: TLookupMethod;
}

/**
 * REDUCER
 */
export default function reducer(user = {}, action: { type: string, user: IUser }) {
  switch (action.type) {
    case SET_USER:
      return action.user;

    default:
      return user;
  }
}

/**
 * THUNK CREATORS
 */

export const createUserTC = (authUser: any) => async (dispatch: Function) => {
  logger.debug('create user');
  try {
    let user = await new FireUsersModel({ id: authUser.id }).create(authUser);
    userId = user.id;
    dispatch(setUser(user));
  } catch (error) {
    logger.error('failed to create user', error);
  }
};

export const getUserTC = (id: string) => async (dispatch: Function) => {
  logger.debug('get user');
  try {
    let user = await new FireUsersModel().getById(id);
    userId = user.id;
    dispatch(setUser(user));
  } catch (error) {
    logger.error('failed to get user', error);
  }
};

export const patchUserTC = (userData: Partial<FireUsersModel> = {}) => async (dispatch: Function) => {
  logger.debug('patch user');
  try {
    let user = await new FireUsersModel({ id: userId }).patch(userData);
    dispatch(setUser(user));
  } catch (error) {
    logger.error('failed to patch user', error);
  }
};

export const logoutTC = () => (dispatch: Function) => {
  userId = null
  dispatch(setUser(null));
};
