import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  withMobileDialog, // required for fullScreen
} from '@material-ui/core';

import { mapToWeekday } from '../services/Helpers';
import './LookBackMethodHelpText.css';

class LookBackMethodHelpText extends Component {
  state = {
    open: false,
  };

  close = () => this.setState({ open: false });
  open = () => this.setState({ open: true });

  mapToSuffix = dateNumber =>
    [
      'st',
      'nd',
      'rd',
      'th',
      'th',
      'th',
      'th',
      'th',
      'th',
      'th',
      'th',
      'th',
      'th',
      'th',
      'th',
      'th',
      'th',
      'th',
      'th',
      'th',
      'st',
      'nd',
      'rd',
      'th',
      'th',
      'th',
      'th',
      'th',
      'th',
      'th',
      'st',
      'nd',
      'rd',
      'th',
      'th',
      'th',
      'th',
      'th',
      'th',
      'th',
    ][dateNumber];

  get helpText() {
    switch (this.props.user.lookupMethod) {
      case 'day':
        return `${mapToWeekday(this.props.date.getDay())}s of every week`;
      case 'date':
        return `${this.props.date.getDate()}${this.mapToSuffix(
          this.props.date.getDate() - 1,
        )} of every month`;
      case 'date_and_month':
        return `${this.props.date.getDate()} \\ ${this.props.date.getFullYear()} of every year`;
      case 'benchmark':
        return `benchmarks intervals`;
      default:
        return '';
    }
  }

  render() {
    return (
      <div className="LookBackMethodHelpText">
        {/* eslint-disable-next-line */}
        <a className="helper-text" onClick={this.open}>
          {this.helpText}
        </a>
        <Dialog
          fullScreen={this.props.fullScreen}
          maxWidth="sm"
          open={this.state.open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div
            className={`dialog-body ${
              this.props.fullScreen ? '' : 'fullscreen'
              }`}
          >
            <DialogContent>
              <DialogContentText className="example">
                {this.helpText}
              </DialogContentText>
              <br />
              <DialogContentText id="alert-dialog-description">
                This help text lets you know how the entries are listed (weekly,
                monthly, yearly)
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.close} color="primary">
                close
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => ({ ...state });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withMobileDialog()(LookBackMethodHelpText));
