import FireEntryModel from '../firebase/FireEntriesModel';
import Logger from '../services/Log';

const logger = new Logger('REMOTE ENTRY');

export default class RemoteEntry {
  static async getByConfig(config) {
    logger.debug('getting by config');
    let entryModel = new FireEntryModel();
    return entryModel
      .getByChildren(config)
      .then(entries => {
        return entries.map(entry => {
          // set defaults
          entry.images = entry.images || [];
          entry.imageUrls = entry.imageUrls || [];
          return entry;
        });
      })
      .catch(err => console.error('fetching entries unsuccessful', err));
  }
}
