// import FireBaseModel from './FireBaseModel'

import { firestore } from './firebase';
import Logger from '../services/Log';

const logger = new Logger('FIRE ENTRY MODEL');

// TODO: extend a base model
export default class FireEntriesModel /* extends FireBaseModel*/ {
  /**
   * PROPERTIES
   */
  databaseRef;
  user;
  // date index
  date;
  // day of the week index
  day;
  // month index
  month;
  year;
  content;
  id;
  images;
  imageUrls;
  // calculated, only exist locally
  dateString;

  constructor(fields) {
    this.databaseRef = firestore.collection('entries');
    Object.assign(this, fields);
  }

  // class method
  getById(id) {
    logger.debug('get by id', id);
    return this.databaseRef
      .doc(id)
      .get()
      .then(this.docSnapToPlainObject);
  }

  async get() {
    logger.debug('get', this.id);
    return await this.databaseRef
      .doc(this.id)
      .get()
      .then(this.docSnapToPlainObject);
  }

  put() {
    let entry = { ...this };
    logger.debug('put', entry);
    delete entry.databaseRef;
    return this.databaseRef
      .add(entry)
      .then(docRef => docRef.get())
      .then(this.docSnapToPlainObject);
  }

  async patch(data) {
    logger.debug('patch', data);
    await this.databaseRef.doc(this.id).update(data);
    return await this.databaseRef
      .doc(this.id)
      .get()
      .then(this.docSnapToPlainObject);
  }

  async delete() {
    logger.debug('delete');
    return this.databaseRef.doc(this.id).delete();
  }

  async getByChildren(children) {
    logger.debug('get by children', children);
    // starts out as CollectionRef, ends up being Query
    // probably should change
    let queriable = this.databaseRef;
    let entries = [];

    for (let key in children) {
      queriable = await queriable.where(key, '==', children[key]);
    }
    await queriable.get().then(querySnapShot => {
      querySnapShot.forEach(doc => {
        entries.push(this.docSnapToPlainObject(doc));
      });
    });
    return entries;
  }

  /**
   * 
   * @return IEntry
   */
  docSnapToPlainObject(docSnap) {
    logger.debug('action complete', docSnap.data());
    const firestoreData = { ...docSnap.data() }
    return {
      id: docSnap.id,
      ...firestoreData,
      dateString: new Date(firestoreData.year, firestoreData.month, firestoreData.date)
    };
  }
}
