import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle, withMobileDialog } from '@material-ui/core';

class SupportDialog extends Component {
  render() {
    return (
      <Dialog open={this.props.isOpen} onBackdropClick={this.props.close}>
        <DialogTitle>Contact Support</DialogTitle>
        <DialogContent>Contact support through support@daily-memories.com</DialogContent>
      </Dialog>
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = state => ({ ...state });

SupportDialog.propTypes = {
  open: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withMobileDialog()(SupportDialog));
