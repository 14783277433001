import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Select,
  InputLabel,
  ListItemText,
  ListItemIcon,
  MenuItem,
  Menu,
  FormControl,
  IconButton,
} from '@material-ui/core';
import {
  Sync,
  YoutubeSearchedFor,
  MoreVert,
  ExitToApp,
  LocalPostOffice,
} from '@material-ui/icons';

import { authentication } from '../firebase/firebase';
import { setDate } from '../store/date';
import { patchUserTC } from '../store/auth';
import { resyncEntriesTC, getEntriesTC } from '../store/entry';
import './Menu.css';
import { isDemo } from '../services/DemoService';
import SupportDialog from './SupportDialog';

// had to change name to Menu_ because name conflict with Menu icon
class Menu_ extends Component {
  state = {
    anchorEl: null,
    openSupport: false,
  };

  constructor(props) {
    super(props);
    this.closeMenu = this.closeMenu.bind(this);
    this.closeSupport = this.closeSupport.bind(this);
    this.logout = this.logout.bind(this);
    this.resyncEntries = this.resyncEntries.bind(this);
    this.onLookupMethodChange = this.onLookupMethodChange.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.openSupport = this.openSupport.bind(this);
    this.resyncToSelectedDate = this.resyncToSelectedDate.bind(this);
  }

  async resyncEntries() {
    await this.props.resyncEntries(this.props.user.id, true);
    this.resyncToSelectedDate();
    this.closeMenu();
  }

  async onLookupMethodChange($event) {
    this.closeMenu();
    await this._setLookupMethod($event.target.value);
    await this.props.getEntries(
      this.props.user,
      this.props.date,
    );
    this.resyncToSelectedDate();
  }

  resyncToSelectedDate() {
    const dateValue = this.props.date.valueOf();
    let today = new Date(dateValue);
    this.props.setDate(today);
  }

  /**
   * set and saves lookup method
   * @params method {string}
   */
  _setLookupMethod = async method => {
    await this.props.patchUser({ lookupMethod: method });
  };

  openMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  closeMenu = () => {
    this.setState({ anchorEl: null });
  };

  logout() {
    authentication.signOut();
    this.closeMenu();
  }

  openSupport() {
    this.setState({ openSupport: true });
    this.closeMenu();
  }

  closeSupport() {
    this.setState({ openSupport: false });
  }

  render() {
    const { anchorEl } = this.state;

    return (
      <div>
        <IconButton
          aria-label="Menu"
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={this.openMenu}
        >
          <MoreVert fontSize="small" />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.closeMenu}
        >
          <MenuItem>
            <ListItemIcon>
              <YoutubeSearchedFor fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <form>
                <FormControl>
                  <InputLabel htmlFor="look-back-method">
                    Look back interval
                  </InputLabel>
                  <Select
                    value={this.props.user.lookupMethod}
                    onChange={this.onLookupMethodChange}
                    inputProps={{
                      name: 'look back by',
                      id: 'look-back-method',
                    }}
                  >
                    <MenuItem value={'benchmark'}>Benchmark (recommended)</MenuItem>
                    <MenuItem disabled={isDemo()}
                      value={'day'}>Week</MenuItem>
                    <MenuItem disabled={isDemo()}
                      value={'date'}>Month</MenuItem>
                    <MenuItem disabled={isDemo()}
                      value={'date_and_month'}>Year</MenuItem>
                  </Select>
                </FormControl>
              </form>
            </ListItemText>
          </MenuItem>

          <MenuItem disabled={isDemo()}
            onClick={this.resyncEntries}>
            <ListItemIcon>
              <Sync fontSize="small" />
            </ListItemIcon>
            <ListItemText inset primary="Resync" />
          </MenuItem>

          <MenuItem disabled={isDemo()}
            onClick={this.logout}>
            <ListItemIcon>
              <ExitToApp fontSize="small" />
            </ListItemIcon>
            <ListItemText inset primary="Log Out" />
          </MenuItem>

          <MenuItem disabled={isDemo()}
            onClick={this.openSupport}>
            <ListItemIcon>
              <LocalPostOffice fontSize="small" />
            </ListItemIcon>
            <ListItemText inset primary="Support" />
          </MenuItem>
        </Menu>
        <SupportDialog isOpen={this.state.openSupport} close={this.closeSupport}></SupportDialog>
      </div >
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setDate: date => dispatch(setDate(date)),
  patchUser: fields => dispatch(patchUserTC(fields)),
  getEntries: (user, date) => dispatch(getEntriesTC(user, date)),
  resyncEntries: (userId, force) => resyncEntriesTC(userId, force),
});

const mapStateToProps = state => ({ ...state });

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Menu_);
